import type { Integration, Client, SpanJSON } from '@sentry/core';

export const spansToIgnore = ['resource', 'browser'];

export const shouldIgnoreSpan = (span: SpanJSON): boolean => {
	return (
		spansToIgnore.includes(span.op ?? '') ||
		spansToIgnore.some((pattern) => span.op?.startsWith(pattern))
	);
};

export class CustomSpanFilterIntegration implements Integration {
	name: string = 'CustomSpanFilterIntegration';
	setup(client: Client): void {
		const options = client.getOptions();
		const originalBeforeSendSpan = options.beforeSendSpan;

		options.beforeSendSpan = (span: SpanJSON): SpanJSON | null => {
			if (shouldIgnoreSpan(span)) {
				// Don't create the span
				return null;
			}
			return originalBeforeSendSpan ? originalBeforeSendSpan(span) : span;
		};
	}
}
