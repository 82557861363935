import * as Sentry from '@sentry/sveltekit';
import { env } from '$env/dynamic/public';
import { CustomSpanFilterIntegration, shouldIgnoreSpan } from '$lib/utils/sentry';

if (env.PUBLIC_SENTRY_DSN) {
	let stage = env.PUBLIC_STAGE;
	if (stage) {
		stage = stage.toUpperCase();
	} else {
		stage = 'local';
	}
	Sentry.init({
		dsn: env.PUBLIC_SENTRY_DSN,
		environment: stage,
		release: env.PUBLIC_RELEASE,

		integrations: [
			CustomSpanFilterIntegration,
			Sentry.replayIntegration({
				/* Please note that updating worker file requires also updating the
				version in the file name as otherwise it will load old cached version */
				workerUrl: '/sentry-replay-worker-8-3-11.min.js'
			}),
			/* Captures all Console API calls via `captureException` or
			`captureMessage`. Helpful for debugging Safari, as it shows fetch
			related errors' details only in console of the device itself */
			Sentry.captureConsoleIntegration({ levels: ['error'] }),
			/* Captures errors on failed requests from Fetch and XHR
			and attaches request and response information. */
			Sentry.httpClientIntegration(),
			/* Extracts all non-native attributes from the error
			object and attaches them to the event as extra data.*/
			Sentry.extraErrorDataIntegration()
		],
		// This enables session replay in Sentry
		// The only need as of now is to record issues therefore only errors are recorded
		replaysSessionSampleRate: 0,
		replaysOnErrorSampleRate: 1.0,
		tracesSampleRate: 0.1,
		sampleRate: 0.1,
		// Will be removed in v 9.0.0
		beforeSendSpan(span) {
			if (shouldIgnoreSpan(span)) {
				return null;
			}
			return span;
		},
		// This option is required for capturing headers and cookies.
		sendDefaultPii: true
	});
}

export const handleError = Sentry.handleErrorWithSentry();
